/* eslint-disable react/button-has-type */
import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Buttons.module.less';
import WrappedLink from '../WrappedLink/WrapperLink';
import Loader from '../Loader/Loader';
import { isExternal } from '../Link/Link';

/**
 * Buttons props types definitions
 */
export type ButtonPropsTypes = {
  /**
   * Button click event handler function
   */
  onClick?: (e: React.MouseEvent<any> | null) => any,
  /**
   * Text value
   */
  text?: string | ReactElement | number;
  /**
   * Text value
   */
  children?: ReactElement | ReactElement[] | string | number;
  /**
   * Add primary class to button
   */
  primary?: boolean;
  /**
   * Add green class to button
   */
  green?: boolean;
  greenDark?: boolean;
  /**
   * Add greenGlass class to button
   */
  greenGlass?: boolean;
  /**
   * Add glass class to button
   */
  glass?: boolean;
  /**
   * Add danger class to button
   */
  danger?: boolean;
  /**
   * Add secondary class to button
   */
  secondary?: boolean;
  /**
   * Add secondary glass class to button
   */
  secondaryGlass?: boolean;
  /**
   * Change button size to large
   */
  large?: boolean;
  /**
   * Change button size to super large
   */
  superLarge?: boolean;
  /**
   * Change button size to small
   */
  small?: boolean;
  /**
   * Change button size to super large
   */
  huge?: boolean;
  /**
   * Add special class to button
   */
  special?: boolean;
  /**
   * Add ghost class to button
   */
  ghost?: boolean;
  /**
   * Add cancel button class to button
   */
  cancel?: boolean;
  /**
   * Additional classes
   */
  className?: string;
  /**
   * Additional id
   */
  id: string;
  /**
   * Used for ButtonLink *link value*
   */
  href?: string;
  /**
   * Used in click event
   */
  link?: boolean | string;
  /**
   * Button type attribute value
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button disabled attribute and extra disabled class
   */
  disabled?: boolean;
  /**
   * Used for ButtonPostForm - form action attribute value
   */
  formAction?: string;
  /**
   * Used for ButtonPostForm - hidden form input name attribute value
   */
  name?: string;
  /**
   * Used for ButtonPostForm - hidden form input value attribute value
   */
  formValue?: string;
  /**
   * Used for ButtonLink - target attribute value
   */
  target?: string;
  /**
   * Is button download
   */
  download?: string;
  /**
   * Is buttonLink external?
   * prop is left for compatibility, don't use it anymore
   */
  external?: boolean;
  /**
   * Loading state
   */
  loading?: boolean;
  /**
   * Html title attribute
   */
  title?: string;
  /**
   *  Html5 form id relation attribute
   */
  form?: string;
  /**
   * Adds margin-left and right (value*4)
   */
  mx?: number;
  /**
   * Adds margin-left (value*4)
   */
  ml?: number;
  /**
   * Adds margin-right (value*4)
   */
  mr?: number;
  /**
   * Adds margin-bottom
   */
  mb?: number;
  /**
   * Adds margin-top
   */
  mt?: number;
  /**
   * Adds width
   */
  width?: number;
};

// Must be filled with other button states and classes

/**
 * Simple button component
 * @component
 * @category Components
 * @subcategory Buttons
 * @borrows ButtonPropsTypes as props
 * @hideconstructor
 * @example
 * return <Button primary large>Large button</Button>
 */
const Button = (props: ButtonPropsTypes) => {
  const {
    disabled,
    onClick,
    primary,
    green,
    greenGlass,
    greenDark,
    secondary,
    large,
    superLarge,
    small,
    huge,
    danger,
    secondaryGlass,
    glass,
    special,
    ghost,
    text,
    className,
    id,
    type,
    loading,
    children,
    link,
    title,
    form,
    cancel,
    mx,
    ml,
    mr,
    mb,
    mt,
    width,
  } = props;

  const [minWidth, setMinWidth] = useState('');
  const buttonRef = useRef(null);
  const history = useHistory();
  const content = text ?? children;
  const marginLeft = (ml || mx) && `${(ml || mx) * 4}px`;
  const marginRight = (mr || mx) && `${(mr || mx) * 4}px`;

  useEffect(() => {
    /* Used to fixing button initial width to solve issue of shrinking in loading state */
    if (buttonRef && buttonRef.current) {
      setMinWidth(buttonRef.current.getBoundingClientRect().width);
    }
  }, []);

  let classNameResult = styles.btn;
  if (primary) classNameResult += ` ${styles.btnPrimary}`;
  if (large) classNameResult += ` ${styles.btnLarge}`;
  if (superLarge) classNameResult += ` ${styles.btnSuperLarge}`;
  if (huge) classNameResult += ` ${styles.btnHuge}`;
  if (small) classNameResult += ` ${styles.btnSmall}`;
  if (danger) classNameResult += ` ${styles.btnDanger}`;
  if (secondary) classNameResult += ` ${styles.btnSecondary}`;
  if (secondaryGlass) classNameResult += ` ${styles.btnSecondaryGlass}`;
  if (glass) classNameResult += ` ${styles.btnPrimaryGlass}`;
  if (secondary) classNameResult += ` ${styles.btnSecondary}`;
  if (special) classNameResult += ` ${styles.btnSpecial}`;
  if (ghost) classNameResult += ` ${styles.btnGhost}`;
  if (cancel) classNameResult += ` ${styles.btnPrimaryCancel}`;
  if (green) classNameResult += ` ${styles.btnGreen}`;
  if (greenGlass) classNameResult += ` ${styles.btnGreenGlass}`;
  if (greenDark) classNameResult += ` ${styles.btnGreenDark}`;
  if (className) classNameResult += ` ${className}`;

  return (
    <button
      style={{
        minWidth,
        marginLeft,
        marginRight,
        marginBottom: `${mb}px`,
        marginTop: `${mt}px`,
        width: width && `${width}px`,
      }}
      ref={buttonRef}
      type={type || 'button'}
      tabIndex={0}
      onClick={link && typeof link === 'string' ? () => history.push(link) : onClick}
      disabled={disabled}
      className={classNameResult}
      id={id}
      title={title ?? 'button'}
      form={form}
    >
      {loading ? <Loader/> : content}
    </button>
  );
};
/**
 * Styled like button anchor element
 * @component
 * @category Components
 * @subcategory Buttons
 * @borrows ButtonPropsTypes as props
 * @hideconstructor
 * @example
 * return <ButtonLink glass text="button preview"/>
 */
export const ButtonLink = (props: ButtonPropsTypes) => {
  const {
    href,
    onClick,
    className,
    target,
    glass,
    large,
    superLarge,
    huge,
    primary,
    secondary,
    green,
    greenGlass,
    greenDark,
    special,
    text,
    download,
    external,
    id,
    disabled,
    mx = 0,
    ml = 0,
    mr = 0,
    mb,
    width,
  } = props;

  const marginLeft = `${(ml || mx) * 4}px`;
  const marginRight = `${(mr || mx) * 4}px`;
  // external prop is left for compatibility and not needed anymore
  return external || download || isExternal(href)
    ? (
      <a
        style={{
          marginLeft,
          marginRight,
          marginBottom: `${mb}px`,
          width: `${width}px`,
        }}
        id={id}
        href={href}
        onClick={onClick}
        target={target}
        download={download}
        className={`${styles.btn} 
        ${primary && styles.btnPrimary} 
        ${secondary && styles.btnSecondary} 
        ${large && styles.btnLarge} 
        ${superLarge && styles.btnSuperLarge} 
        ${huge && styles.btnHuge} 
        ${glass && styles.btnPrimaryGlass} 
        ${green && styles.btnGreen} 
        ${greenGlass && styles.btnGreenGlass} 
        ${greenDark && styles.btnGreenDark} 
        ${disabled && styles.btnDisabled} 
        ${special && styles.btnSpecial} 
        ${className || ''}`}
      >
        {text}
      </a>
    )
    : (
      <WrappedLink
        className={`${styles.btn} 
        ${primary && styles.btnPrimary} 
        ${secondary && styles.btnSecondary} 
        ${large && styles.btnLarge} 
        ${superLarge && styles.btnSuperLarge} 
        ${huge && styles.btnHuge} 
        ${glass && styles.btnPrimaryGlass} 
        ${green && styles.btnGreen} 
        ${greenGlass && styles.btnGreenGlass} 
        ${greenDark && styles.btnGreenDark} 
        ${disabled && styles.btnDisabled} 
        ${special && styles.btnSpecial} 
        ${className || ''}`}
        style={{
          marginLeft,
          marginRight,
          marginBottom: `${mb}px`,
          width: `${width}px`,
        }}
        to={href}
        id={id}
      >
        {text}
      </WrappedLink>
    );
};
/**
 * Special component that generate simple form and used to POST actions
 * to resolve some backend logic
 * @component
 * @category Components
 * @subcategory Buttons
 * @borrows ButtonPropsTypes as props
 * @hideconstructor
 * @example
 * return <ButtonPostForm primary text="button preview"/>
 */
export const ButtonPostForm = (props: ButtonPropsTypes) => {
  const {
    formAction,
    name,
    formValue,
    primary,
    large,
    superLarge,
    huge,
    special,
    glass,
    green,
    greenGlass,
    text,
    className,
    children,
    disabled,
  } = props;
  return (
    <form action={formAction} method="post">
      <input type="hidden" name={name} value={formValue}/>
      <button
        disabled={disabled}
        type="submit"
        className={`${styles.btn} ${primary && styles.btnPrimary} ${large && styles.btnLarge} ${superLarge && styles.btnSuperLarge} ${huge && styles.btnHuge} ${special && styles.btnSpecial} ${glass && styles.btnPrimaryGlass} ${green && styles.btnGreen} ${greenGlass && styles.btnGreenGlass} ${className || ''}`}
      >
        {children || text}
      </button>
    </form>
  );
};

type InputValue = {
  name: string,
  value: any,
};
type FormSubmitButtonProps = {
  values: InputValue[]
};

export const FormSubmitButton = (props: ButtonPropsTypes & FormSubmitButtonProps) => {
  const {
    formAction,
    primary,
    large,
    superLarge,
    huge,
    special,
    glass,
    text,
    className,
    values,
    id,
  } = props;
  return (
    <form action={formAction} method="post">
      {values.map((item: InputValue) => (
        <input
          key={btoa(item.name)}
          type="hidden"
          name={item.name}
          value={item.value}
        />
      ))}
      <button
        id={id}
        type="submit"
        className={`${styles.btn} ${primary && styles.btnPrimary} ${large && styles.btnLarge} ${superLarge && styles.btnSuperLarge} ${huge && styles.btnHuge} ${special && styles.btnSpecial} ${glass && styles.btnPrimaryGlass} ${className || ''}`}
      >
        {text}
      </button>
    </form>
  );
};

export default Button;
